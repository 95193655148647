<template>
    <login-form />
</template>

<script>
import loginForm from '@/patterns/loginForm';

export default {
    components: {
        loginForm
    }
}
</script>
