<template>
    <component :is="markup" :title="$t('auth.signin.title')" :subtitle="$t('auth.signin.subtitle')">
        <v-form-group :label="$t('label.mobile')" :v="v$.form.mobile" class="mb-4">
            <v-form-input v-model="form.mobile" type="tel" autocomplete="mobile" />
        </v-form-group>

        <v-form-group :label="$t('label.password')" :v="v$.form.password">
            <v-form-input v-model="form.password" type="password" autocomplete="password" />

            <template #helper>
                <v-button class="text-blue-400 hover:underline" @click="$redirect('forgot-password')">
                    {{ $t('forgot_password') }}
                </v-button>
            </template>
        </v-form-group>

        <template #footer>
            <v-button type="submit" variant="primary" class="w-full" :loading="loading" @click="handleLogin">
                {{ $t('action.signin') }}
            </v-button>

            <p class="text-center pt-8 text-blue-gray-500">
                {{ $t('no_account') }}

                <v-button v-if="markup === 'v-modal'" class="text-blue-600 hover:underline" @click="$emit('abort')">
                    {{ $t('action.signup') }}
                </v-button>

                <localized-link v-else :to="{ name: 'signup' }" class="text-blue-600 hover:underline">
                    {{ $t('action.signup') }}
                </localized-link>
            </p>
        </template>
    </component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { phone } from '@/assets/javascript/vuelidate.js';

export default {
    props: {
        markup: {
            type: String,
            default: 'auth-layout'
        } 
    },

    emits: ['confirm', 'abort'],

    setup () {
        return { v$: useVuelidate() }
    },

    data: () => ({
        loading: false,
        form: {
            mobile: null,
            password: null
        }
    }),

    validations: () => ({
        form: {
            mobile: { required, phone },
            password: { required, minLength: minLength(8) },
        }
    }),

    computed: {
        ...mapGetters ('auth', [
            'is_admin'
        ])
    }, 

    methods: {
		...mapActions('auth', [
            'login',
            'getCurrentUser',
		]),

        handleLogin (event) {
            event.preventDefault();

			this.v$.form.$touch();

            if (this.v$.form.$invalid) return;

            this.loading = true;

			this.login(this.form).then(() => {
                if (this.markup === 'v-modal') this.$emit('confirm');
                else {
                    this.getCurrentUser().then(() => {
                        const home = this.is_admin ? 'admin-home' : 'home';

                        this.$redirect(this.$route.redirectedFrom?.name || home, this.$route.redirectedFrom?.params);
                    });
                }               
            }).catch(() => {
                this.loading = false;
            });
		},
    }
};
</script>